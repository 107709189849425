import React, { useState, useEffect } from 'react';
import { getRank } from '../service/risk.service';
import useStore from '../lib/store/useStore';
import axios from 'axios';
import LoadingIndicator from './LoadingIndicator';

function CeoRiskRank() {
  const { com_name, selectedDateRange, dateRange } = useStore(state => ({
    com_name: state.com_name,
    selectedDateRange: state.selectedDateRange,
    dateRange: state.dateRange,
  }));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const TOP_N = 7;
  const source = axios.CancelToken.source();

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const start_date = selectedDateRange[0];
      const fetchedData = await getRank(start_date, dateRange, source.token);
      console.log("Fetched Data:", fetchedData);

      if (Array.isArray(fetchedData)) {
        fetchedData.sort((a, b) => {
          if (b.ai_score !== a.ai_score) {
            return b.ai_score - a.ai_score;
          } else if (a.company_name === com_name) {
            return -1;
          } else if (b.company_name === com_name) {
            return 1;
          } else {
            return 0;
          }
        });

        let topNData = fetchedData.slice(0, TOP_N);

        const selectedCompanyData = fetchedData.find(item => item.company_name === com_name);
        if (selectedCompanyData && !topNData.some(item => item.company_name === com_name)) {
          topNData.pop();
          topNData.push(selectedCompanyData);
          topNData.sort((a, b) => {
            if (b.ai_score !== a.ai_score) {
              return b.ai_score - a.ai_score;
            } else if (a.company_name === com_name) {
              return -1;
            } else if (b.company_name === com_name) {
              return 1;
            } else {
              return 0;
            }
          });
        }

        console.log(`Final Top ${TOP_N} Data:`, topNData);
        setData(topNData);
      } else {
        console.error('Fetched data is not an array:', fetchedData);
        setError('데이터 형식이 올바르지 않습니다.');
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('요청이 취소되었습니다.');
      } else {
        console.error('데이터 가져오는 중 오류 발생:', error);
        setError('데이터를 불러오는 중 오류가 발생했습니다.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetchData(source);

    return () => {
      source.cancel('컴포넌트가 언마운트되어 요청이 취소되었습니다.');
    };
  }, [com_name, selectedDateRange, dateRange]);

  return (
    <div className="relative w-full">
      <p className="mx-auto text-base font-medium pt-2 p-3 pb-4 flex items-center whitespace-nowrap">
        <span className="pr-2 overflow-hidden no-select whitespace-nowrap text-ellipsis">
          기업총수 평판순위
        </span>
        <span className="tooltip flex items-center">
          <i className="fa-regular fa-circle-question text-gray-500"></i>
          <span className="tooltip-text">해당 기간 내 기업 총수의<br/> 평판 순위를 보여줍니다.</span>
        </span>
      </p>
      {loading ? (
        <div className='pt-40'>
          <LoadingIndicator />
        </div>
      ) : (
        <table className="w-full bg-gray-800 border border-gray-700 table-fixed text-base">
          <thead>
            <tr>
              <th className="py-4 px-3 border-b border-gray-700 text-center text-base w-1/2 whitespace-nowrap overflow-hidden text-ellipsis">
                이름
              </th>
              <th className="py-4 px-3 border-b border-gray-700 text-center text-base w-1/2 whitespace-nowrap overflow-hidden text-ellipsis">
                AI Score
              </th>
            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan="2" className="text-center py-4 text-red-500">{error}</td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={item.company_name || index} className={item.company_name === com_name ? 'text-yellow-400' : ''}>
                  <td className="py-2.5 px-2 text-center text-base truncate border-b border-gray-700 max-w-[10ch]">
                    {item.company_name || ''}
                  </td>
                  <td className="py-2.5 px-2 text-center text-base border-b border-gray-700">
                    {item.ai_score !== undefined && item.ai_score !== null ? item.ai_score : 0}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default CeoRiskRank;
