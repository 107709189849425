import { Chart as ChartJS } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(annotationPlugin);

const generateChartOptions = (dateRange, summaries, cmpSummaries, chartData, selectedDateRange, isSmallScreen) => {
  const formattedSelectDate = new Date(selectedDateRange).toISOString().split('T')[0];
  const dateIndex = chartData.labels.indexOf(formattedSelectDate);

  const fontSize = isSmallScreen ? 14 : 14;
  const maxTicksLimit = isSmallScreen ? 10 : 10;

  const formattedLabels = chartData.labels.map((label, index) => {
    if (dateRange === 'daily') {
      return label; // 일간 데이터는 그대로 사용
    } else {
      return isSmallScreen ? label.slice(5) : label; // 주간 및 월간 데이터는 포맷 조정
    }
  });

  // annotation 설정을 별도로 정의
  const createAnnotationConfig = (datasets, dateIndex) => {
    // 모든 데이터셋이 숨겨진 경우 annotationConfig를 비활성화
    const allHidden = datasets.every(dataset => dataset.hidden === true);
    if (allHidden || dateIndex === -1) {
      return {}; // 모든 데이터셋이 숨겨졌을 때 노란선을 제거
    }

    // 노란선 어노테이션만 추가
    return {
      selectedLine: {
        type: 'line',
        scaleID: 'x',
        value: dateIndex,
        borderColor: 'rgba(255, 206, 86, 0.7)',
        borderWidth: 2,
        label: {
          enabled: true,
          content: '선택한 날짜',
          position: 'start',
          backgroundColor: 'rgba(255, 206, 86, 0.8)',
          color: '#000',
          font: { weight: 'bold', size: fontSize },
        },
      },
    };
  };

  // 초기 annotation 설정
  const annotationConfig = createAnnotationConfig(chartData.datasets, dateIndex);

  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          color: 'white',
        },
        border: {
          color: 'rgba(255, 255, 255, 0.7)',
          width: 2,
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)',
          lineWidth: 1,
          drawOnChartArea: true,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: maxTicksLimit,
          color: 'white',
          font: { weight: 'bold', size: fontSize },
          callback: function(value, index) {
            return formattedLabels[index];
          },
        },
      },
      y: {
        title: {
          display: true,
          color: 'white',
        },
        border: {
          color: 'rgba(255, 255, 255, 0.7)',
          width: 2,
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)',
          lineWidth: 1,
          drawOnChartArea: true,
        },
        ticks: {
          stepSize: 25,
          color: 'white',
          font: { weight: 'bold', size: fontSize },
        },
        max: 100,
        min: 50,
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'center',
        display: true,
        labels: {
          color: '#ffffff',
          font: {
            size: fontSize,
          },
        },
        onClick: (event, legendItem, legend) => {
          const chart = legend.chart;
          const datasetIndex = legendItem.datasetIndex;
          const meta = chart.getDatasetMeta(datasetIndex);
          meta.hidden = !meta.hidden; // 데이터셋의 가시성 토글

          // 가시성 토글 후 annotation 업데이트
          chart.options.plugins.annotation.annotations = createAnnotationConfig(chart.data.datasets, dateIndex);
          chart.update();
        },
      },
      tooltip: {
        backgroundColor: '#000000',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        borderColor: '#ffffff',
        borderWidth: 1,
        callbacks: {
          title: (tooltipItems) =>
            dateRange === 'daily' ? `Time: ${tooltipItems[0].label}` : `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const datasetLabel = chartData.datasets[tooltipItem.datasetIndex].label;
            return `${datasetLabel} Score : ${tooltipItem.raw}`;
          },
          afterLabel: (tooltipItem) => {
            const summaryList = tooltipItem.datasetIndex === 0 ? summaries : cmpSummaries;
            const summary = summaryList[tooltipItem.dataIndex] || 'No summary available';
            const maxLineLength = isSmallScreen ? 35 : 70;
            const [firstLine, secondLine] = [
              summary.slice(0, maxLineLength),
              summary.length > maxLineLength ? summary.slice(maxLineLength, maxLineLength * 2) + '...' : '',
            ];
            return ['', firstLine, secondLine].filter(Boolean);
          },
        },
      },
      annotation: {
        common: {
          drawTime: 'afterDatasetsDraw',
        },
        annotations: annotationConfig,
      },
    },
    animation: {
      duration: 0,
    },
  };
};

export default generateChartOptions;
